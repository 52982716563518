<template>
  <div>
  </div>
</template>

<script>
import downloadFileApi from "@/apis/download-file";
export default {
  data() {
    return {

    }
  },
  mounted() {
    // 近來先check有沒有過期 沒有就馬上開連結下載
    this.fetchDownloadFile()
  },
  methods: {
    async fetchDownloadFile() {
      try {
        const { data } = await downloadFileApi.show(this.$route.params.download_file_id);
        window.open(data.url);
        this.$router.push({
          name: "MyConsoleDownloadFileList",
          params: { org_code: this.$route.params.org_code },
        });
        return;
      } catch (error) {
        console.log(error)
        this.$swal("失敗", error.response.data.message, "error").then(()=>{
          this.$router.push({
            name: "MyConsoleDownloadFileList",
            params: { org_code: this.$route.params.org_code },
          });
          return;
        });
      }
    }
  }
}
</script>
